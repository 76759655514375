import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 4×8\\@75% 1RM`}</p>
    <p>{`Glutei Ham Raise 4×8`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`40-Double Unders`}</p>
    <p>{`20-Burpees Over Bar`}</p>
    <p>{`10-Power Cleans (135/95)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our next Mobility class will be today at 11:00.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Work on our back lot will wrap up soon! You will not be able to use
the Washington Street entrance for the next few days.  Get ready for
some outdoor wods just in time for the nice weather!`}</em></strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`Reminder:  Bootcamp classess are Tuesday-Friday at 9:30 and evening
classes are **`}</strong>{` now offered Monday-Friday at 4:00, 5:00 & 6:00pm.`}<strong parentName="p">{`*
*`}</strong>{`   ***`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      